import React, { useEffect, useState, useCallback} from "react";
import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { unityContext } from "./../pages/Play";

const UserContext = React.createContext();
const options = {
  name: 'Floodplains'
 };
  
const wallet = new BeaconWallet(options);

export const useUserContext = () => {

  const app = React.useContext(UserContext);
    if (!app) {
      throw new Error(
        `!app`
      );
    }
  return app;
};

export const UserContextProvider = ({ children }) => {
  
  const [app, setApp] = React.useState("");
  const [address, setAddress] = useState("");
  const [tezos, setTezos] = useState(new TezosToolkit("https://mainnet.api.tez.ie"));
  const [activeAccount, setActiveAccount] = useState("")

  useEffect(() => {
    const getLoggedIn = async () => {
      if (await wallet?.client?.getActiveAccount()) { 
        setActiveAccount(await wallet?.client?.getActiveAccount());
        const address =  await wallet.getPKH();
        setAddress(address);
        console.log("address " + address);
        tezos.setWalletProvider(wallet);
        setTezos(tezos)
      }
    };
    getLoggedIn();
  }, [tezos]);
  
  async function logIn() {
    app.currentWallet && await app.currentWallet?.logOut();
    await wallet.client.clearActiveAccount();
    await wallet.client.requestPermissions({
      network: {
        type: 'mainnet',
      },
    });
    tezos.setWalletProvider(wallet);
    let address=await wallet.getPKH()
    setAddress(address);
    setActiveAccount(await wallet?.client?.getActiveAccount());
    unityContext.send("WalletLoader", "GetData", address);
  }

  async function logOut() {
    await wallet.client.clearActiveAccount();
    setActiveAccount("")
    setAddress("");
    //  window.location.reload();
    //send to unity
    unityContext.send("WalletLoader", "Logout");
  }

  const objktSwapContract = "KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC";

  async function collect(swapId, xtzAmount) {
    console.log("swapId " + swapId);
    console.log("swxtzAmountapId " + xtzAmount);
    try {
      const interact = await tezos.wallet.at(objktSwapContract)
        const op = await interact.methods['fulfill_ask'](swapId)
        if(op) {await op.send({
          amount: xtzAmount,
          mutez: true,
          storageLimit: 310
      }) 
      // await op.confirmation(2)}
    }

    } catch(e) {
      console.log('Error:', e);
      return false;
    }
    return true;
  };

  const getWalletAddress = async () => {
    if (await wallet?.client?.getActiveAccount()) { 
      setActiveAccount(await wallet?.client?.getActiveAccount());
      const address =  await wallet.getPKH();
      setAddress(address);
      console.log("address " + address);
      tezos.setWalletProvider(wallet);
      setTezos(tezos)

      if (address) {
        return address;
      }
    }
  };

  const wrapped = { ...app, tezos, logIn, logOut, activeAccount, address, collect, getWalletAddress};

  return (
   
    <UserContext.Provider value={wrapped}>
      {children}
    </UserContext.Provider>
  
  );
};

export default UserContextProvider;